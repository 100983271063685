import React, { useEffect, useState } from "react";

const CountingComponent = () => {
  const [count, setCount] = useState(1);
  const [timeCompleted, setTimeCompleted] = useState(0);
  let totalTimes = 0;

  useEffect(() => {
    if (count <= 10) {
      if (count === 10) {
        // Đạt đến step 10, không gọi thêm setTimeout nữa
        return;
      }

      const timer = setTimeout(() => {
        setCount((prevCount) => prevCount + 1);
      }, calculateInterval());

      return () => clearTimeout(timer);
    }
  }, [count]);

  const calculateInterval = () => {
    const totalTime = 210000; // Tổng thời gian 210 giây (3.5 phút)
    const timePerStep = totalTime / 10; // Thời gian mỗi bước

    // const randomTime = Math.random() * timePerStep;
    const randomTime =
      Math.floor(Math.random() * (timePerStep - 15000 + 1)) + 15000;

    console.log("randomTime", randomTime);

    // Đảm bảo thời gian không nhỏ hơn 100ms
    let randomTimeCount = 0;
    if (count === 9) {
      randomTimeCount = totalTime - timeCompleted;
    } else {
      randomTimeCount = randomTime > 100 ? randomTime : 100;
    }

    totalTimes += randomTimeCount; // Accumulate randomTimeCount into totalTimes variable
    setTimeCompleted((prevTime) => prevTime + totalTimes); // Update timeCompleted with the accumulated time

    return randomTimeCount; // Return the calculated randomTimeCount as the interval
  };

  return <>{count}</>;
};

export default CountingComponent;
