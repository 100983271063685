import React from "react";

import img1 from "../../assets/images/howItWork/img1.png";
import img2 from "../../assets/images/howItWork/img2.png";
import img3 from "../../assets/images/howItWork/img3.png";
import nextArrow from "../../assets/images/howItWork/next-arrow.png";
import prevArrow from "../../assets/images/howItWork/prev-arrow.png";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

import { Box, Typography } from "@mui/material";

const isMobile = window.innerWidth < 768;

const titleStyle = {
  marginBottom: isMobile ? "25px" : "39px",

  color: "#00A872",
  textAlign: "center",
  fontFamily: "Poppins",
  fontSize: isMobile ? "20px" : "25px",
  fontStyle: "normal",
  fontWeight: 600,
  lineHeight: "normal",
  textTransform: "uppercase"
};

const SimpleSlider = () => {
  const slider = React.useRef(null);
  const settings = {
    customPaging: function (i) {
      return <button>{i + 1}</button>;
    },
    dots: true,
    dotsClass: "slick-dots slick-thumb",
    infinite: true,
    speed: 1000,
    // autoplay: true,
    slidesToShow: isMobile ? 1 : 3,
    slidesToScroll: 1,
    spaceBetween: 50
  };

  const options = [
    {
      image: img1,
      title: "1. Submit your information",
      desc: "Complete our simple, secure and fast online form in just few minutes."
    },
    {
      image: img2,
      title: "2. Fast loan decision",
      desc: "Complete our simple, secure and fast online form in just few minutes."
    },
    {
      image: img3,
      title: "3. Check your account",
      desc: "Once your request is approved by lenders, the funds will be directly tranferred into your account in as soon as the lenders proceed your request."
    }
  ];
  return (
    <Box
      sx={{
        ".slick-dots": {
          bottom: 0
        },
        ".slick-dots ": {
          display: "flex !important",
          width: "110%",
          left: "50%",
          transform: "translateX(-50%)",

          "li ": {
            flex: 1,
            height: "auto",

            button: {
              width: "100%",
              height: "12px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "10px",
              background: "#F6F6F6",

              color: "#000",
              textAlign: "center",
              fontFamily: "Poppins",
              fontSize: "10px",
              fontStyle: "normal",
              fontWeight: 500,
              lineHeight: "normal",

              ":before": {
                display: "none"
              }
            }
          },
          ".slick-active": {
            button: {
              background: "#00A872",
              color: "#fff"
            }
          }
        },
        ".slick-prev": {
          top: "35%",
          left: "-20px",
          ":before": {
            content: '""',
            position: "absolute",
            left: 0,
            top: 0,
            width: "11.358px",
            height: "20px",
            background: `url(${prevArrow}) no-repeat center`,
            backgroundSize: "100%"
          }
        },
        ".slick-next": {
          top: "35%",
          right: "-20px",
          ":before": {
            content: '""',
            position: "absolute",
            right: 0,
            top: 0,
            width: "11.358px",
            height: "20px",
            background: `url(${nextArrow}) no-repeat center`,
            backgroundSize: "100%"
          }
        }
      }}
    >
      <Slider ref={slider} {...settings}>
        {options.map((item, index) => (
          <Box
            key={index}
            sx={{
              marginBottom: isMobile ? "0" : "20px",
              padding: "0 10px"
            }}
          >
            <Box>
              <Box
                className="card-image"
                sx={{
                  marginBottom: isMobile ? "10px" : "17px",
                  display: "flex",
                  justifyContent: "center"
                }}
              >
                <img src={item.image} alt="" />
              </Box>
            </Box>

            <Box
              sx={{
                paddingBottom: isMobile && "20px"
              }}
            >
              <Typography
                variant="h4"
                component="h4"
                sx={{
                  color: "#606060",
                  textAlign: "center",
                  fontFamily: "Poppins",
                  fontSize: isMobile ? "14px" : "16px",
                  fontStyle: "normal",
                  fontWeight: 700,
                  lineHeight: "normal"
                }}
              >
                {item.title}
              </Typography>
              <Typography
                variant="p"
                sx={{
                  color: "#606060",
                  textAlign: "center",
                  fontFamily: "Poppins",
                  fontSize: isMobile ? "12px" : "14px",
                  fontStyle: "normal",
                  fontWeight: 400,
                  lineHeight: "normal"
                }}
              >
                {item.desc}
              </Typography>
            </Box>
          </Box>
        ))}
      </Slider>
    </Box>
  );
};
const HowItWork = () => {
  return (
    <Box
      className="how-it-work"
      id="howItWork"
      sx={{
        textAlign: "center",
        padding: isMobile ? "25px 20px 0" : "0",
        maxWidth: "1041px",
        margin: isMobile ? "0 auto" : "0 auto 54px"
      }}
    >
      <Box
        sx={{
          backgroundColor: "#fff",
          padding: isMobile ? "14px 38px" : "35px 42px 38px",
          boxShadow: "0px 1px 20px rgba(0, 0, 0, 0.10)",
          borderRadius: "8px"
        }}
      >
        <Box>
          <Typography
            variant="h3"
            component="h3"
            sx={titleStyle}
            // data-aos="fade-left"
          >
            How it works
          </Typography>
          <SimpleSlider />
        </Box>
      </Box>
    </Box>
  );
};

export default HowItWork;
