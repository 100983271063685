import React from "react";
import Navbar from "../../../components/Navbar/Navbar";
import Footer from "../../../components/Footer/Footer";

import "./Privacy.scss";
import { Link } from "react-router-dom";

const Privacy = () => {
  return (
    <div className="Privacy">
      <Navbar />

      <div className="container-privacy">
        <div className="box-privacy">
          <h2 className="title">Privacy Policy</h2>
          <p className="last-update">Last Updated: 09-22-2023</p>

          <h4>Agreement and Acknowledgment</h4>
          <p>
            This Service is operated by <b>lowrateseeker.com</b> (hereinafter
            "Company"). This Privacy Policy is an Agreement between Company and
            You (hereinafter "You" and/or "Your") as the user of this Service
            and Company's products and services and governs how the Company uses
            the information collected as well as the protection of the personal
            information submitted by You to this Service.
            <br />
            By accessing our website, you acknowledge that this site is for
            individuals who are over the legal age of 18 and individuals who are
            legal US citizens,&nbsp; you know about our marketing practices and
            agree with them. You have nothing against receiving advertisements,
            newsletters, marketing messages and other promotional materials from
            us.&nbsp;
            <br />
            <p>
              Any questions regarding our Privacy Policy or information
              collection practices should be sent to us via email at{" "}
              <b>
                <Link target="_blank" to="mailto:contact@lowrateseeker.com">
                  contact@lowrateseeker.com
                </Link>
              </b>
            </p>
          </p>
          <h4>Notifications of Privacy Policy Changes</h4>
          <p>
            We reserve the right to revise, modify, update&nbsp; this Privacy
            Policy without further notice to its users. You should continue to
            review this Policy periodically to ensure that you are up-to-date
            and knowledgeable about any changes that are taking place.
          </p>
          <h4>What information we collect</h4>
          <p>
            We gather the following information from you, personally
            identifiable information and non-personally identifiable
            information.
            <br />
            1. Information knowingly provided by you through online forms,
            registration forms, surveys and/or other entries, such as your name,
            street address, e-mail address, telephone number (home, work and
            mobile), date of birth, Social Security Number, bank account
            information, information relating to monthly income, employment
            information, and other personal, financial or demographic
            information;
            <br />
            2. Cookies. As you navigate through and interact with this Website,
            we may use automatic data collection technologies ("Cookies") to
            collect certain information, including but not limited to,
            information about your equipment, browsing actions and patterns,
            operating system, internet connection and location data. The
            information we collect automatically may include personal
            information, and we may maintain it or compound it with personal
            information we collect in other ways or receive from third parties.
            Cookies help us improve this Website so we are able to deliver a
            better and more personalized experience by reducing unwanted
            information that you might otherwise receive
            <br />
            3. Information (user-specific or aggregate) concerning the pages our
            visitors access and referring URLs; and
            <br />
            4. The e-mail addresses of visitors who communicate with this site
            and Steady Cash via e-mail.
            <br />
            Please note: Submitting information to us is voluntary and we may
            also collect your personally identifiable information when you
            submit information at the Website but do not complete the full
            registration process.
          </p>
          <h4>How we use collected date</h4>
          <p>
            For the purposes of data usage, we use the information we collect to
            enhance your experience at our Website and to help us to offer
            content we determine may be of interest to you. We use your contact
            information to offer you promotional material from our partners. We
            may also use your personal, demographic, and profile data to improve
            our Website, conduct statistical analysis, and for marketing,
            promotional, editorial, or feedback purposes. Information collected
            by us may be added to our databases and used for future emails or
            postal mailings of products and services.
          </p>
          <h4>How we share your information</h4>
          <p>
            By providing your personally identifiable information, you allow us
            to process your request and connect you with a lender. That also
            means that we have the right to share this information with a third
            party because a lender cannot approve your loan without knowing
            anything about you.
            <br />
            Please note that lenders may verify your personal information by
            sending requests to national databases and credit reporting
            agencies. Such inquiries may affect your credit score. We may share
            some of your data with our affiliates, partners and other accredited
            third parties for the purposes of business management, security,
            advertising, marketing, consumer behavior research, and consumer
            support improvement.
            <br />
            We reserve the right to share your information with another company
            that has acquired our business, a part of it, or fused with us. In
            case such a situation takes place, it&rsquo;s possible that we will
            inform you about these changes by email or with an announcement
            posted on our website. We may share your personally identifiable
            information if it is required by the law and is necessary to proceed
            with a legal process or to protect our rights.
            <br />
            By collecting and sharing your non-personally identifiable
            information with third parties, we improve our technical service,
            study behavior of our customers and gather web statistics important
            to the management of our business. We may share it with other third
            parties for these purposes.
          </p>
          <h4>Do not call Registry Compliance</h4>
          <p>
            It is every consumer&rsquo;s right to add his or her telephone
            number to the National Do Not Call Registry, which, in general,
            applies to any plan, program, or campaign to sell goods or services
            through interstate phone calls. However, a company may call a
            consumer with whom it has an established business relationship for
            up to eighteen (18) months after the consumer&rsquo;s last purchase,
            delivery or payment, and for up to three (3) months after the
            consumer submits an application or an inquiry to the company, even
            if the consumer&rsquo;s number has been added to the National Do Not
            Call Registry.
            <br />
            <b>lowrateseeker.com</b> does not make outbound phone calls to
            consumers, and therefore does not maintain an internal do not call
            registry of its own. If you no longer wish to receive phone calls
            from a lender you got connected with, you will have to contact them
            directly and request that your telephone number be placed on their
            specific do not call list.
          </p>
          <h4>Data Retention and Security</h4>
          <p>
            We will retain your information for as long as needed to provide you
            with products and Services, and to comply with our accounting and
            legal obligations, resolve disputes, and enforce our agreements.
            Your Personal Information will be destroyed or permanently deleted
            as required by applicable law.
            <br />
            <b>lowrateseeker.com</b> uses reasonable technical, physical, and
            administrative security measures to reduce the risk of loss, misuse,
            unauthorized access, disclosure, or modification of your
            information. Unfortunately, no transmission of data over the
            Internet is guaranteed to be completely secure. While we strive to
            protect your Personal Information, we cannot ensure or warrant the
            security or confidentiality of any Personal Information you transmit
            to us.
          </p>
          <h4>Links to Third-Party Websites</h4>
          <p>
            Our Website, as well as the email messages sent with respect to our
            Services, may contain links or access to websites operated by third
            parties that are beyond our control. For example, if you click to
            request for a product such as a loan via <b>lowrateseeker.com</b> ,
            you may be linked to a third-party website to complete a request.
            Links or access to third parties from our Services are not an
            endorsement by us of such third parties, or their websites,
            requests, products, services, or practices. We are not responsible
            for the privacy policy, terms and conditions, practices or the
            content of such third parties.
          </p>
          <h4>E-Consent</h4>
          <p>
            Please read carefully and acknowledge this important information
            which might help you for future reference.&nbsp;
            <br />
            By participating in this website and clicking the &lsquo;Request
            Now&rsquo; button, you agree to provide your personal details to
            request to be in touch with our lender and you have received and
            reviewed this E-Consent Policy, and that you consent to conduct
            transactions using electronic signatures, electronic disclosures,
            electronic records, and electronic contract documents
            ('Disclosures'). In the attempt of connecting you with the lender,
            the lenders need your consent to use and accept electronic
            signatures, records, and disclosures ('E-Consent').&nbsp;
            <br />
            Option for Paper or Non-Electronic Records.
            <br />
            Regarding the paper records, you can directly connect with the
            lender to&nbsp; request the Disclosures without any fee.&nbsp;&nbsp;
            <br />
            Scope of Consent.&nbsp;
            <br />
            Your provided information will be shared with participating lenders,
            third-party partners including advertisers, networks and others who
            may not provide financial solutions, the connection between you and
            the information-shared partners may be on any mobile device,
            including phones, smart-phones, tablets, and any other electronic
            device that allows the consumer to connect to the Internet. There is
            no guarantee by the owner of this website about the approved loans.
            By exercising this E-Consent, these 3rd parties may process your
            information and interact during all online interactions with you
            electronically. They may also send you notices electronically
            related to its interactions and transactions. Disclosures may be
            provided online at your lender&rsquo;s website, if applicable, and
            may be provided by e-mail. See above for guidance on how to request
            paper copies.
            <br />
            Consenting to Do Business Electronically.
            <br />
            Prior to the final business decision with the lender, it is
            important for you to consider these two points below:
            <li>Conduct business electronically</li>
            <li>
              Require hardware and software capabilities described as below.
            </li>
            Hardware and Software Requirements.&nbsp; To access and retain the
            Disclosures and Communications electronically, you will need to use
            the following computer software and hardware:
            <li>Internet access with 128-bit encryption</li>
            <li>Adobe Acrobat Reader 6 or higher</li>
            <li>Ability to Print</li>
            <li>Internet Explorer 8 or higher</li>
            <li>Email Access</li>
            <li>Firefox version 3.6 or higher</li>
            Your Ability to Access Disclosures.
            <br />
            &nbsp;By clicking and submitting your information, you assent to our
            terms and conditions. You acknowledge that you can access the
            disclosures in the designated formats described above.
            <br />
            Withdrawing Consent.
            <br />
            &nbsp;If you are connected with one or more third-party lenders, you
            are free to withdraw your E-Consent with those lenders at any time,
            and at no charge. However, if you withdraw this E-Consent before
            being connected with a lender, you may be prevented from obtaining
            an offer as this consent is required, for this one time transaction,
            to facilitate the connection and to conduct business. Contact your
            lender directly if you wish to withdraw this E-Consent. If you
            decide to withdraw this E-Consent, the legal effectiveness,
            validity, and enforceability of prior electronic disclosures will
            not be affected.
            <br />
            Change to Your Contact Information. You should keep your lender
            informed of any change in either your electronic address or physical
            mailing address. Your lender will have specific instructions on how
            to update your contact information, if necessary. Please note that{" "}
            <b>lowrateseeker.com</b> cannot update your information for you
            information for you.
          </p>
          <h4>California Rights -&nbsp; Shine the Light</h4>
          <p>
            Pursuant to Section 1798.83 of the California Civil Code, California
            residents who provide personal information in obtaining products or
            services for any purposes have the right to request and obtain from
            us about the customer information we shared with all partners
            including third parties. This information includes the names,
            addresses of those businesses.&nbsp;
            <br />
            All of our partners will be listed clearly in our Marketing Partners
            part, but if you have any request to obtain this information, please
            send an email message to contact@<b>lowrateseeker.com</b> with the
            subject line: &ldquo;Request for California Rights to obtain
            information&rsquo;. Please note that only information covered by
            &lsquo;Shine the light&rsquo; is&nbsp; shared in our response.&nbsp;
          </p>
          <h4>Opt-out Rights</h4>
          <p>
            We may collect information about you particularly your email address
            from sources other than you, such as from social media websites
            (i.e. Facebook, LinkedIn, Twitter or others), blogs, other users and
            our business partners.
            <br />
            If you do not wish to receive email offers or newsletters from us,
            you can opt out of receiving email information from us (other than
            emails related to the completion of your registration, correction of
            user data, change of password and other similar communications
            essential to your transactions through our Services) by using the
            unsubscribe process at the bottom of the email.
          </p>
          <h4>Push Notifications</h4>
          <p>
            We may send push notifications or alerts to your device. We use push
            information to send you notifications related to offers. At any
            time, you can manage your push notification preferences or
            deactivate these notifications at any time by turning off the
            notifications in the browser settings of your device.
          </p>
          <h4>Contact us</h4>
          <p>
            If there is something you would like to discuss with us related to
            the site, please contact us following the below contact details. If
            you have any questions regarding the loan request, please contact
            the lender directly.
          </p>
          <h4>Mailing Address:</h4>
          <li>
            <b>lowrateseeker.com</b>
          </li>
          <li>2803 Philadelphia Pike</li>
          <li>Suite B #1020</li>
          <li>Claymont, DE 19703</li>
          <li>United States</li>
          <li>
            Email:{" "}
            <b>
              <Link target="_blank" to="mailto:contact@lowrateseeker.com">
                contact@lowrateseeker.com
              </Link>
            </b>
          </li>
        </div>
      </div>

      <Footer desc={false} />
    </div>
  );
};

export default Privacy;
