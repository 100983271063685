import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { Link as Scroll } from "react-scroll";

import Logo from "../../assets/images/logo.png";
import Menu from "../../assets/images/menu.png";

import "./Navbar.scss";
import { RatesFee } from "../popups/RatesFee/RatesFee";
import { FAQs } from "../popups/FAQs/FAQs";
import { HowItWorksPopup } from "../popups/HowItWorksPopup/HowItWorksPopup";
import { Box } from "@mui/material";

const Navbar = () => {
  const windowWidth = window.innerWidth;
  const isMobile = windowWidth < 768;

  const [openMenu, setOpenMenu] = useState(false);
  const [openRateFee, setOpenRateFee] = useState(false);
  const [openFAQs, setOpenFAQs] = useState(false);
  const [openHowItWorks, setOpenHowItWorks] = useState(false);
  // GET VARIABLE FROM URL
  const url = new URL(window.location.href);
  const params = new URLSearchParams(url.search);
  const testMode = params.get("tm");
  const testSold = params.get("ts");
  const clickId = params.get("clickId");

  testMode && localStorage.setItem("testMode", testMode);
  testSold && localStorage.setItem("testSold", testSold);
  clickId && localStorage.setItem("clickId", clickId);

  const location = useLocation();
  const subURL = location.pathname.split("/")[1];
  const subURL1 = location.pathname.split("/")[2];

  return (
    <>
      {subURL === "form" ? (
        <Box
          sx={{
            padding: "15px",
            textAlign: "center"
          }}
        >
          <Link to="/">
            <img src={Logo} alt="Logo" />
          </Link>
        </Box>
      ) : (
        <nav
          className={`navbar ${
            subURL === "congratulations" && "congratulations-nav"
          }`}
          id="navbar"
        >
          {openRateFee && (
            <RatesFee
              openRateFee={openRateFee}
              setOpenRateFee={setOpenRateFee}
            />
          )}
          {openFAQs && <FAQs openFAQs={openFAQs} setOpenFAQs={setOpenFAQs} />}

          {openHowItWorks && (
            <HowItWorksPopup
              openHowItWorks={openHowItWorks}
              setOpenHowItWorks={setOpenHowItWorks}
            />
          )}
          <Box
            className="container"
            sx={{
              "::after": {
                display: subURL === "apply" && !subURL1 ? "block" : "none"
              }
            }}
          >
            {isMobile && (
              <div className="logo">
                <Link to="/">
                  <img src={Logo} alt="Logo" />
                </Link>
              </div>
            )}
            <button
              id="show-menu"
              onClick={() => setOpenMenu(!openMenu)}
              style={
                openMenu
                  ? { transform: "rotate(90deg)" }
                  : { transform: "rotate(0)" }
              }
            >
              <img src={Menu} alt="" />
            </button>

            <ul
              className={openMenu ? "menu show" : "menu"}
              style={openMenu ? { top: "66px" } : { top: "-100%" }}
            >
              {!isMobile && (
                <li>
                  <Link to="/" className="menu-item">
                    Home
                  </Link>
                </li>
              )}
              <li>
                {subURL === "" ? (
                  <Scroll
                    className="menu-item"
                    to="howItWork"
                    spy={true}
                    smooth={true}
                    duration={800}
                    offset={-120}
                    onClick={() => setOpenMenu(!openMenu)}
                  >
                    How it works
                  </Scroll>
                ) : (
                  <Link
                    onClick={() => {
                      setOpenMenu(!openMenu);
                      setOpenHowItWorks(!openHowItWorks);
                    }}
                    className="menu-item"
                  >
                    How it works
                  </Link>
                )}
              </li>
              {!isMobile && (
                <li
                  style={{
                    flex: 1
                  }}
                >
                  <div className="logo">
                    <Link to="/">
                      <img src={Logo} alt="Logo" />
                    </Link>
                  </div>
                </li>
              )}
              <li onClick={() => setOpenMenu(!openMenu)}>
                <Link
                  onClick={() => {
                    setOpenMenu(!openMenu);
                    setOpenRateFee(!openRateFee);
                  }}
                  className="menu-item"
                >
                  Rates & Fees
                </Link>
              </li>
              <li>
                <Link
                  onClick={() => {
                    setOpenMenu(!openMenu);
                    setOpenFAQs(!openFAQs);
                  }}
                  className="menu-item"
                >
                  FAQs
                </Link>
              </li>
            </ul>
          </Box>
        </nav>
      )}
    </>
  );
};

export default Navbar;
