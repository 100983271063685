import React from "react";
import Navbar from "../../../components/Navbar/Navbar";
import Footer from "../../../components/Footer/Footer";

import "./TermsOfUse.scss";

const TermsOfUse = () => {
  return (
    <div className="TermsOfUse">
      <Navbar />
      <div className="container-privacy">
        <div className="box-privacy">
          <h2 className="title">Terms of Use</h2>

          <h4>Our Terms of Use</h4>
          <p>
            <b>lowrateseeker.com</b> (&ldquo;we&rdquo; or &ldquo;our&rdquo;)
            owns and operates the website located at <b>lowrateseeker.com</b>,
            (the &ldquo;Site&rdquo;). <b>lowrateseeker.com</b> provides and
            maintains this Site for your information, conditioned on your
            acceptance of the terms, conditions, and notices contained in this
            agreement (&ldquo;Terms of Use&rdquo;). By accessing and using the
            Site, you accept and agree, without limitation, to the following:
          </p>
          <h4>1. Site Usage</h4>
          <p>
            <b>lowrateseeker.com</b> is developed to give you the opportunity to
            connect with reliable lenders. This site is for those who are over
            18 years old and US legal residents. Our role is not a lender, we
            are only providing a fee-of-charge place to help you in applying for
            the funds and connecting with the lenders to solve your financial
            request. We do not lend money and play no role in both sides'
            communication process, and we do not represent or promote the
            services of any particular lender. It is no obligation for you to
            use our site, provide your personal information, or accept an offer
            from a third-party lender.
          </p>
          <p>
            For any funding-related questions, you should contact your lenders
            directly to address as we do not participate in the lending process.
            Hence, we will not be responsible and liable for any
            misunderstanding, fees, damages caused during the communication
            between you and your lender.
            <br />
            When you access and join the site, you acknowledge that you know our
            Terms of Use. You understand and consent that some personal details
            will be required to share for the purpose of connecting with
            lenders. You are aware that we may share this information with them
            and that they, in their turn, may also use it. As stated clearly on
            our Privacy Policy, you agree and consent to all parts mentioned on
            our&nbsp; Privacy Policy.&nbsp;
          </p>
          <p>
            Please note that the application review is a process that lenders
            will implement to verify your personal information to see your
            eligibility, which may affect your credit score when they address
            national databases and credit reporting agencies with such requests.
          </p>
          <h4>2. Product/Services</h4>
          <p>
            By accessing the site and providing your information, you understand
            and agree that <b>lowrateseeker.com</b> is not working as a lender,
            not requiring product purchases or issuing any features of products
            or services on the site. If there are any advertised
            products/services available by our partners after leaving our site,
            the responsibility for those products and services will be taken by
            the providers only.&nbsp;
          </p>
          <h4>3. Intellectual Property Rights</h4>
          <p>
            All properties including trademarks, titles, characters, designs,
            graphics, content, logo, and other properties are owned by
            <b> lowrateseeker.com</b> and protected by the copyright laws of the
            US. You are not allowed to copy, reproduce&hellip;our intellectual
            properties without our prior written permission.
          </p>
          <h4>4. Limitation of Liability</h4>
          <p>
            By agreeing to participate the site, you are voluntary to provide
            your personal details and understand all the contents, services
            presented by the site, the company (the site) does not take any
            responsibilities for any direct, indirect, incidental, consequential
            or other form of loss or damage, including but not limiting the loss
            of profit, data, opportunities, costs, personal injury/wrongful
            death and other forms of damage, that you may encounter due to the
            site usage.
          </p>
          <p>
            Some jurisdictions do not recognize or allow the exclusion of
            certain specific warranties such as incidental or consequential
            damages. In such cases, the total liability of the company in the
            case of such loss or damage related to the usage of the site is
            limited to the extent of the total amount paid by you to the company
            in connection with the subject matter of the particular dispute.
          </p>
          <h4>5. Compliance With Laws</h4>
          <p>
            You agree to comply with all applicable laws, statutes, ordinances
            and regulations regarding your use of the Site and your acceptance
            or purchase of products or services through this Site's third party
            vendors.
          </p>
          <h4>6. E-Consent</h4>
          <p>
            E-Consent is an electronic signature of yours. By clicking links
            like &ldquo;Request Now&rdquo;, &ldquo;I Agree&rdquo;,&nbsp;
            &ldquo;Submit&rdquo;, &ldquo;E-Sign&rdquo;, &ldquo;Consent&rdquo;,
            etc., you agree to give your electronic signature, which means you
            also consent that you agree to communicate with us and third parties
            electrically via phone, email, sms,...
          </p>
          <h4>7. Violations</h4>
          <p>
            Any actions against our Terms of Use are considered as violations,
            in these cases, we have the right to terminate your account without
            notice and remove all of your provided materials from our
            sites.&nbsp;
          </p>
          <h4>8. Modifications and Updates</h4>
          <p>
            For important purposes to ensure your understanding about all the
            terms, please read all information carefully and usually review to
            update some new modifications as we have the right to modify and
            update the present Terms of Use without notice.&nbsp;
          </p>
        </div>
      </div>

      <Footer desc={false} />
    </div>
  );
};

export default TermsOfUse;
