import React, { useEffect } from "react";
import { Box } from "@mui/material";
import Footer from "../../components/Footer/Footer";
import Navbar from "../../components/Navbar/Navbar";
import { useSearchParams } from "react-router-dom";

const NewForm = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    // Create a script element
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.async = true;
    script.src = "https://loansaccount.com/form/applicationInit.js";
    script.id = "_lg_form_script_"; // Set a unique ID for the script tag

    // Set attributes for form initialization
    window._lg_form_init_ = {
      aid: "16319",
      template: "neo",
      ref: searchParams.get("ref") ?? "",
      click_id: searchParams.get("click_id") ?? "",
      requested_amount: searchParams.get("requested_amount") ?? "",
      first_name: searchParams.get("first_name") ?? "",
      last_name: searchParams.get("last_name") ?? "",
      email: searchParams.get("email") ?? ""
    };
    // Append the script to the document body
    document.body.appendChild(script);

    // Clean up function to remove the script when the component unmounts
    return () => {
      const scriptElement = document.getElementById("_lg_form_script_");
      if (scriptElement) {
        document.body.removeChild(scriptElement);
        delete window._lg_form_init_;
      }
    };
  }, []); // Empty dependency array ensures the effect runs once after the component mounts

  return (
    <Box
      className="step-1"
      // sx={{
      //   background:
      //     "linear-gradient(\n    180deg,\n    #f1ffec 0.56%,\n    rgba(241, 255, 236, 0) 57.94%\n  )"
      // }}
    >
      <Navbar />
      <div id="_lg_form_"></div>
      <Footer desc={false} />
    </Box>
  );
};

export default NewForm;
