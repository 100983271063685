module.exports = {
  checkIsMobile: () => {
    const windowWidth = window.innerWidth;
    return windowWidth < 768;
  },
  trimWhiteSpaceObjValue(obj = {}) {
    for (let key in obj) {
      if (typeof obj[key] === "string") {
        obj[key] = obj[key].trim();
      }
    }
  }
};
