import React from "react";

import img1 from "../../assets/images/whyChooseUS/img1.png";
import img2 from "../../assets/images/whyChooseUS/img2.png";
import img3 from "../../assets/images/whyChooseUS/img3.png";
import cardBG from "../../assets/images/whyChooseUS/cardBG.png";
import cardBGMB from "../../assets/images/whyChooseUS/cardBG-mb.png";

import { Box, Card, Typography } from "@mui/material";

const isMobile = window.innerWidth < 768;

const cardOptions = [
  {
    icon: img1,
    title: "Easy to request",
    desc: "Online request is an ideal option for your to request everywhere."
  },
  {
    icon: img2,
    title: "All Credit Types Welcome",
    desc: "Your credit histories might not be good but you still have chances based on lenders’ reviews."
  },
  {
    icon: img3,
    title: "Secure for you",
    desc: "We prioritize the data security by applying the latest technology to protect your information."
  }
];
const ListCard = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: isMobile ? "column" : "row",
        gap: "20px"
      }}
    >
      {cardOptions.map((item, index) => (
        <Card
          key={index}
          sx={{
            flex: 1,
            display: "flex",
            flexDirection: isMobile ? "row" : "column",

            justifyContent: "space-between",
            alignItems: "center",
            gap: "20px",

            padding: "15px 10px",
            position: "relative",

            boxShadow: "unset",
            overflow: "unset",
            background: `url(${
              isMobile ? cardBGMB : cardBG
            }) no-repeat top center`,
            backgroundSize: isMobile
              ? "100% 100%"
              : "calc(100% + 20px) calc(100% + 10px)"

            // ":before": {
            //   content: '""',
            //   position: "absolute",
            //   bottom: isMobile ? "unset" : 0,
            //   left: isMobile ? "unset" : 0,

            //   right: isMobile ? 0 : "unset",
            //   width: isMobile ? "80%" : "100%",
            //   height: isMobile ? "100%" : "60%",
            //   borderRadius: "0 0 8px 8px",

            //   boxShadow: isMobile
            //     ? "4px 0px 4px 0px #00a87252"
            //     : "0px 4px 5px 0px #00a87252"
            // }
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              flex: 1
            }}
          >
            <img src={item.icon} alt="" />
          </Box>
          <Box
            sx={{
              flex: 5
            }}
          >
            <Typography
              variant="h3"
              component="h3"
              sx={{
                color: "#606060",
                fontFamily: "Poppins",
                fontSize: isMobile ? "12px" : "14px",
                fontStyle: "normal",
                fontWeight: 700,
                lineHeight: "normal",
                textAlign: isMobile ? "left" : "center",
                marginBottom: "5px"
              }}
            >
              {item.title}
            </Typography>
            <Typography
              component="p"
              sx={{
                display: "inline-block",
                color: "#606060",
                fontFamily: "Poppins",
                fontSize: isMobile ? "12px" : "14px",
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "normal",
                textAlign: isMobile ? "left" : "center"
              }}
            >
              {item.desc}
            </Typography>
          </Box>
        </Card>
      ))}
    </Box>
  );
};

const WhyChooseUS = () => {
  return (
    <Box
      className="why-choose-us"
      sx={{
        maxWidth: "1041px",
        margin: isMobile ? "0 auto" : "0 auto 47px",
        padding: isMobile ? "30px 20px" : "0"
      }}
    >
      <Box
        sx={{
          padding: isMobile ? "30px 20px" : "30px 44px 40px",

          boxShadow: "0px 1px 20px rgba(0, 0, 0, 0.10)",
          borderRadius: "8px",

          background: "#fff"
        }}
      >
        <Typography
          component="h3"
          variant="h3"
          sx={{
            marginBottom: isMobile ? "10px" : "5px",
            color: "#00A872",
            fontFamily: "Poppins",
            fontSize: isMobile ? "20px" : "25px",
            fontStyle: "normal",
            fontWeight: 600,
            lineHeight: "normal",
            textTransform: "uppercase",
            textAlign: isMobile ? "left" : "center"
          }}
        >
          Why choose us?
        </Typography>
        <Box
          sx={{
            textAlign: isMobile ? "left" : "center"
          }}
        >
          <Typography
            component="p"
            sx={{
              color: "#606060",
              fontFamily: "Poppins",
              fontSize: "16px",
              fontStyle: "normal",
              fontWeight: 400,
              lineHeight: "normal",

              display: "inline-block",
              marginBottom: isMobile ? "20px" : "26px"
            }}
          >
            <b>lowrateseeker.com</b> is a partner who seeks to earn your trust
            in finding a suitable loan from a reliable lender.
          </Typography>
        </Box>
        <ListCard />
      </Box>
    </Box>
  );
};

export default WhyChooseUS;
