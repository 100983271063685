import React, { useEffect } from "react";

import "./RedirectPageUpgrade.scss";
import { Link } from "react-router-dom";
import logo from "../../../assets/images/logo.png";
import { Box } from "@material-ui/core";
import MyCircularProgressbar from "../../../components/CircularProgressbar/CircularProgressbar";

const RedirectPageUpgrade = () => {
  useEffect(() => {
    setTimeout(() => {
      const redirectURL = localStorage.getItem("redirectURL");
      if (redirectURL) window.location.href = redirectURL;
    }, 2000);
  });

  return (
    <Box
      className="redirect-upgrade"
      style={{
        overflowX: "hidden",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        minHeight: "100vh"
      }}
      sx={{
        background:
          "linear-gradient(\n    180deg,\n    #f1ffec 0.56%,\n    rgba(241, 255, 236, 0) 57.94%\n  )"
      }}
    >
      <nav>
        <div className="logo">
          <Link to="/">
            <img src={logo} alt="" />
          </Link>
        </div>
      </nav>
      <Box
        sx={{
          width: "100%",
          padding: "0 15px"
        }}
      >
        <div className="step-main">
          <div className="redirect-content">
            <h1>
              We are sorry! The lender is not around now to get connected, let's
              try another time!
            </h1>
            {/* <h3 className="configuration">Congratulation!</h3> */}
            <h3 className="text">
              You might need some useful information and tips which help you
              gain better chances for the funds. Don't miss out, check now!
            </h3>

            <MyCircularProgressbar page="upgrade" />

            {/* <div className="loading-text">
              <h3>
                REDIRECTING
                <span className="dot-one"> .</span>
                <span className="dot-two"> .</span>
                <span className="dot-three"> .</span>
              </h3>
            </div> */}
          </div>
        </div>
      </Box>

      <footer>
        <p>© 2023 lowrateseeker.com All rights reserved.</p>
      </footer>
    </Box>
  );
};

export default RedirectPageUpgrade;
