import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Box } from "@material-ui/core";

import "./CircularProgress.scss";

const useStylesFacebook = makeStyles((theme) => ({
  top: {
    color: "#48C51A"
  },
  circle: {
    strokeLinecap: "round"
  }
}));

export default function MyCircularProgressbar({
  timeCompleted,
  verified,
  verifiedComplete,
  step,
  page
}) {
  const classes = useStylesFacebook();

  const [progress, setProgress] = useState(0);
  const [isVerified, setIsVerified] = useState(false);
  const isMobile = window.innerWidth < 768;

  useEffect(() => {
    let startTime = Date.now();
    let animationFrameId;

    const updateProgress = () => {
      const currentTime = Date.now();
      const elapsedTime = currentTime - startTime;
      let newProgress = (elapsedTime / timeCompleted) * 100;

      // Làm tròn newProgress đến hai chữ số thập phân
      newProgress = newProgress.toFixed(0);

      if (newProgress >= 100) {
        cancelAnimationFrame(animationFrameId);

        setIsVerified(true);
        // ĐẶT LẠI TIẾN TRÌNH
        setProgress(0);

        setTimeout(() => {
          step !== 2 && setIsVerified(false);
        }, 500);
      } else {
        setProgress(newProgress);
        animationFrameId = requestAnimationFrame(updateProgress);
      }
    };

    animationFrameId = requestAnimationFrame(updateProgress);

    return () => {
      cancelAnimationFrame(animationFrameId);
    };
  }, [step, timeCompleted]);

  return (
    <Box>
      <div
        className={`loader ${isVerified && step !== 3 ? "verified-box" : ""}`}
      >
        <div
          className={`circle circle1 ${
            isVerified && step !== 3 ? "verified" : ""
          }`}
        >
          {!!page ||
            (!isVerified && (
              <>
                <CircularProgress
                  variant="determinate"
                  value={progress ? parseInt(progress) : 0}
                  className={classes.top}
                  classes={{
                    circle: classes.circle
                  }}
                  size={100}
                  thickness={3}
                />
                <Box
                  sx={{
                    position: "absolute"
                  }}
                >
                  <h5>{progress ? parseInt(progress) : 0}%</h5>
                  {step === 0 ? (
                    <p>Verify Loan Request</p>
                  ) : step === 1 ? (
                    <p>
                      Analysing your
                      <br /> information
                    </p>
                  ) : step === 2 ? (
                    <p>Searching Lenders</p>
                  ) : (
                    ""
                  )}
                </Box>
              </>
            ))}
          {(isVerified && step === 3) ||
            ((page === "upstart" || page === "upgrade") && (
              <h5
                style={{
                  color: "#48C51A",
                  textAlign: "center",
                  fontFamily: "Poppins",
                  fontSize: isMobile ? "10px" : "14px",
                  fontStyle: "normal",
                  fontWeight: 600,
                  lineHeight: "normal"
                }}
              >
                Redirect...
              </h5>
            ))}
        </div>
        <div className="circle circle2"></div>
        <div className="circle circle3"></div>
        <div className="circle circle4"></div>
        <div className="circle circle5"></div>
      </div>
    </Box>
  );
}
