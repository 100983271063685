import { Link, useLocation } from "react-router-dom";

import logo from "../../assets/images/ft-logo.png";
import ssl from "../../assets/images/ssl.png";
import secure from "../../assets/images/secure.png";
import { Link as Scroll } from "react-scroll";

import { Box, Typography } from "@mui/material";

const menuOptions = [
  {
    title: "Contact us",
    path: "/contact-us"
  },
  {
    title: "Terms of Use",
    path: "/terms-of-use"
  },
  {
    title: "Privacy Policy",
    path: "/privacy"
  },
  {
    title: "Do not sell my information",
    path: "/do-not-sell-information"
  },
  {
    title: "Disclaimer",
    path: "/disclaimer"
  },
  {
    title: "Unsubscribe",
    path: "/unsubscribe"
  }
];

const Footer = ({ desc = true }) => {
  const windowWidth = window.innerWidth;
  const isMobile = windowWidth < 768;
  const location = useLocation();
  const menuURL = location.pathname.split("/")[1];
  const menuItemStyle = {
    color: "#000",
    textAlign: "center",
    fontFamily: "Poppins",
    fontSize: isMobile ? "12px" : "16px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal"
  };
  return (
    <Box className="footer" id="footer">
      <Box
        sx={{
          background: menuURL === "" ? "#F6F6F6" : "#fff"
        }}
      >
        <Box
          sx={{
            maxWidth: "1230px",
            margin: "0 auto",
            padding: isMobile ? "20px 20px 10px" : "50px 15px",
            borderTop: "2px solid #00000024"
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: isMobile ? "26px" : "50px"
            }}
          >
            <Box
              className="logo"
              sx={{
                maxWidth: isMobile ? "123px" : "100%"
              }}
            >
              {menuURL === "" ? (
                <Scroll
                  className="menu-item"
                  to="navbar"
                  spy={true}
                  smooth={true}
                  duration={800}
                  offset={-120}
                  style={{
                    cursor: "pointer"
                  }}
                >
                  <img src={logo} alt="" />
                </Scroll>
              ) : (
                <Link to="/">
                  <img src={logo} alt="" />
                </Link>
              )}
            </Box>
            <Box
              className="certificate"
              sx={{
                display: "flex",
                gap: "20px"
              }}
            >
              <Box
                className="ssl"
                sx={{
                  maxWidth: isMobile ? "59px" : "100%"
                }}
              >
                <img src={ssl} alt="" />
              </Box>
              <Box
                className="secure"
                sx={{
                  maxWidth: isMobile ? "36px" : "100%"
                }}
              >
                <img src={secure} alt="" />
              </Box>
            </Box>
          </Box>

          {desc && (
            <Typography
              component="p"
              sx={{
                color: "#606060",
                textAlign: "justify",
                fontFamily: "Poppins",
                fontSize: isMobile ? "12px" : "14px",
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "140%"
              }}
            >
              The operator of this website is not a lender, or a representative,
              broker, agent of any lenders and we do not make any loan or credit
              decisions. This website does not constitute an offer or
              solicitation to lend. The role of this website is to submit the
              information you provide to a lender who has the whole rights of
              reviewing and approving for a loan. By submitting your
              information, you agree to allow participating lenders to verify
              your information and check your credit. Not all lenders can
              provide the maximum amount advertised. The time of cash transfer
              may vary between lenders and may depend on your individual
              financial institution. In some circumstances faxing may be
              required. Not all lenders from our system operate in all US
              states, and the states serviced by this website may change from
              time to time and without notice. If you have any questions about
              your loan, please contact your lеndеr directly. Short term cash
              loans are meant to address immediate cash needs and are not a
              long-term solution for financial problems. Residents of some
              states may not be eligible for a short term cash loan based upon
              lender requirements.
            </Typography>
          )}
        </Box>
      </Box>
      <Box
        sx={{
          padding: isMobile ? "12px 20px 27px" : "33px 0",
          background: "#D7DDDA"
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            flexWrap: "wrap",
            gridGap: "15px 20px",
            gap: "15px 20px",
            margin: isMobile ? "0 auto 18px" : "0 auto 33px",
            maxWidth: "1230px",
            padding: isMobile ? "0" : "0 15px"
          }}
        >
          {menuURL === "" ? (
            <Scroll
              style={menuItemStyle}
              to="navbar"
              spy={true}
              smooth={true}
              duration={800}
              offset={-120}
            >
              Home
            </Scroll>
          ) : (
            <Link to="/" style={menuItemStyle}>
              Home
            </Link>
          )}
          {menuOptions.map((item, index) => (
            <Link key={index} to={item.path} style={menuItemStyle}>
              {item.title}
            </Link>
          ))}
        </Box>
        <Box
          sx={{
            textAlign: "center"
          }}
        >
          <Typography
            component="p"
            sx={{
              color: "#606060",
              textAlign: "center",
              fontFamily: "Poppins",
              fontSize: isMobile ? "10px" : "14px",
              fontStyle: "normal",
              fontWeight: 400,
              lineHeight: "normal"
            }}
          >
            © 2023 Lowrateseeker.com All rights reserved.
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
export default Footer;
