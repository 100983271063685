import React, { useEffect, useRef, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";

import DataProtected from "../../assets/images/security/DataProtected.png";
import SSL from "../../assets/images/security/SSL.png";
import Clear from "../../assets/images/security/Clear.png";
import BannerImage from "../../assets/images/banner.png";
import BannerImageMobile from "../../assets/images/banner-mb.png";

import LoadingButton from "@mui/lab/LoadingButton";

import { useDispatch, useSelector } from "react-redux";
import { addContact } from "../../redux/apiCalls";
import { useNavigate } from "react-router-dom";

import { RatesFee } from "../popups/RatesFee/RatesFee";

import {
  Autocomplete,
  Box,
  FormControl,
  FormHelperText,
  MenuItem,
  Select,
  TextField,
  Typography
} from "@mui/material";
import { publicRequest } from "../../requestMethods";
import { amountOptions } from "./options";

import "./Banner.scss";
import {
  ListBoxCustom,
  MenuProps,
  btnSubmitCustom,
  labelCustom,
  menuItemCustom,
  securityText,
  selectCustom,
  textFieldCustom,
  textInputStyles
} from "./style";
import {
  searchFirstNameApi,
  searchLastNameApi,
  sendDataBrevo,
  sendDataEdelivery
  // sendDataMailchimp,
  // sendDataMailjet,
  // sendDataMyMarketing
} from "../../utils/callApi";
import { trimWhiteSpaceObjValue } from "../../utils/functions";

const Banner = () => {
  const windowWidth = window.innerWidth;
  const isMobile = windowWidth < 768;
  const [searchParams, setSearchParams] = useSearchParams();

  const [openRateFee, setOpenRateFee] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const [isSelectOpen, setIsSelectOpen] = useState(false);
  const emailDomains = [
    "gmail.com",
    "outlook.com",
    "hotmail.com",
    "aol.com",
    "yahoo.com",
    "icloud.com",
    "yandex.com"
  ];
  const contactRedux = useSelector((state) => state?.contact?.contact);

  const [amount, setAmount] = useState(
    contactRedux?.amount ?? amountOptions[4].value
  );

  const [firstName, setFirstName] = useState(contactRedux?.firstName ?? "");
  const [firstNameOptions, setFirstNameOptions] = useState([]);
  const [openFirstName, setOpenFirstName] = useState(false);

  const [lastName, setLastName] = useState(contactRedux?.lastName ?? "");
  const [lastNameOptions, setLastNameOptions] = useState([]);
  const [openLastName, setOpenLastName] = useState(false);

  const [emailOptions, setEmailOptions] = useState([]);
  const [openEmailOptions, setOpenEmailOptions] = useState(false);

  const [email, setEmail] = useState(contactRedux?.email ?? "");

  const [ipAddress, setIpAddress] = useState(contactRedux?.ipAddress ?? "");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const getIpAddress = async () => {
      const yourIp = await publicRequest.get(
        "https://api.trustedonlineloans.com/your-ip"
      );
      const res = yourIp?.data.ip;
      if (res) {
        setIpAddress(res);
      }
    };

    if (!ipAddress) {
      getIpAddress();
    }
  }, [ipAddress]);

  const handleInputChange = (e, newInputValue) => {
    if (newInputValue.includes("@")) {
      const [email, domain] = newInputValue.split("@");
      const filteredOptions = emailDomains
        .filter((option) => option.includes(domain))
        .map((option) => `${email}@${option}`);
      setEmailOptions(filteredOptions);
    } else {
      setEmailOptions([]);
    }
  };

  const timeoutRef = useRef(null);
  // CALL API SEARCH FIRSTNAME
  const handleSearchFirstName = async (firstName) => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    const isValidInput = firstName.length > 0 && firstName.length < 50;
    // Wait for 1 second before making the API call
    timeoutRef.current = setTimeout(async () => {
      if (isValidInput) {
        const res = await searchFirstNameApi(firstName);
        setFirstNameOptions(res ?? []);
      }
    }, 300);
  };
  // CALL API SEARCH LASTNAME
  const handleSearchLastName = async (lastName) => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    const isValidInput = lastName.length > 0 && lastName.length < 50;

    // Wait for 1 second before making the API call
    timeoutRef.current = setTimeout(async () => {
      if (isValidInput) {
        const res = await searchLastNameApi(lastName);
        setLastNameOptions(res ?? []);
      }
    }, 300);
  };

  // SEND DATA MAIL PROVIDER
  const sendDataToAllServices = (email, firstName, lastName) => {
    sendDataBrevo(email, firstName, lastName);
    sendDataEdelivery(email, firstName, lastName);
    // sendDataMailjet(email, firstName, lastName);
    // sendDataMyMarketing(email, firstName, lastName);
    // sendDataMailchimp(email, firstName, lastName);
  };

  // SUBMIT FUNC
  const {
    control,
    handleSubmit,
    setError,
    formState: { errors }
  } = useForm({
    // mode: "onChange"
  });
  const onSubmit = async (data) => {
    setLoadingButton(!loadingButton);

    data.ipAddress = ipAddress;
    data.userAgent = navigator.userAgent;

    trimWhiteSpaceObjValue(data);

    sendDataToAllServices(data?.email, data?.firstName, data?.lastName);

    const res = await addContact(dispatch, data);
    if (res?.status === 200) {
      navigate(
        `/form?requested_amount=${amount}&first_name=${firstName}&last_name=${lastName}&email=${email}&ref=${
          searchParams.get("source") ?? ""
        }&click_id=${searchParams.get("clickId") ?? ""}`
      );
    } else {
      setError("email", {
        type: "error",
        message: res?.message
      });
      setLoadingButton(false);
    }
    return;
  };

  return (
    <Box
      className="banner"
      id="banner"
      sx={{
        padding: isMobile ? "12px 20px 0" : "0",
        maxWidth: "1041px",
        margin: isMobile ? "0 auto" : "46px auto 54px"
      }}
    >
      {openRateFee && (
        <RatesFee openRateFee={openRateFee} setOpenRateFee={setOpenRateFee} />
      )}
      <Box
        sx={{
          display: isMobile ? "block" : "flex",
          padding: isMobile ? "20px" : "27px 36px",

          boxShadow: "0px 1px 20px rgba(0, 0, 0, 0.10)",
          backgroundColor: "#fff",
          borderRadius: "8px",
          border: "1px solid #00A872"
        }}
      >
        <Box
          sx={{
            flex: "50%",
            display: "flex",
            borderRadius: "10px",
            overflow: "hidden"
          }}
        >
          <img src={isMobile ? BannerImageMobile : BannerImage} alt="" />
        </Box>
        <Box
          className="banner-box"
          sx={{
            flex: "50%",
            padding: isMobile ? "16px 0 0" : "0 45px"
          }}
        >
          <Typography
            component="h3"
            sx={{
              marginBottom: "11px",

              color: "#00A872",
              fontFamily: "Poppins",
              fontSize: isMobile ? "20px" : "25px",
              fontStyle: "normal",
              fontWeight: 600,
              lineHeight: "normal",
              textTransform: "uppercase",
              textAlign: isMobile ? "center" : "left"
            }}
          >
            Get Fast and Reliable Loans up to $5,000
          </Typography>
          {/* FORM */}
          <form onSubmit={handleSubmit(onSubmit)}>
            <Controller
              name="amount"
              defaultValue={amount}
              control={control}
              rules={{ required: "Loan amount is required!" }}
              render={({ field }) => (
                <FormControl
                  fullWidth
                  error={!!errors?.amount}
                  size={isMobile ? "small" : "medium"}
                  sx={{
                    marginBottom: isMobile ? "7px" : "15px"
                  }}
                >
                  <FormHelperText sx={labelCustom}>
                    Loan Amount ($100 - $5,000)
                  </FormHelperText>
                  <Select
                    {...field}
                    onChange={(e) => {
                      const value = e.target.value;
                      field.onChange(value);
                      setAmount(value);
                    }}
                    value={amount}
                    displayEmpty
                    MenuProps={{
                      ...MenuProps,
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "center"
                      }
                    }}
                    sx={selectCustom}
                    onClose={() => {
                      setIsSelectOpen(false);
                    }}
                    onOpen={() => {
                      setIsSelectOpen(true);
                    }}
                    className={isSelectOpen ? "show" : ""}
                  >
                    {amountOptions.map((option, index) => (
                      <MenuItem
                        key={index}
                        value={option.value}
                        sx={menuItemCustom}
                        size="small"
                      >
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                  {errors?.amount && (
                    <FormHelperText>
                      {errors?.amount && errors?.amount.message}
                    </FormHelperText>
                  )}
                </FormControl>
              )}
            />

            <Box
              sx={{
                display: "flex",
                gap: "10px"
              }}
            >
              {/* firstName */}
              <Controller
                name="firstName"
                control={control}
                defaultValue={firstName}
                rules={{
                  required: "FirstName is required!"
                }}
                render={({ field: { ref, ...field } }) => (
                  <FormControl
                    fullWidth
                    sx={{
                      marginBottom: isMobile ? "7px" : "15px"
                    }}
                  >
                    <FormHelperText sx={labelCustom}>First name</FormHelperText>
                    <Autocomplete
                      {...field}
                      freeSolo
                      id="firstName"
                      name="firstName"
                      onOpen={() => setOpenFirstName(true)}
                      onClose={() => setOpenFirstName(false)}
                      disableClearable
                      value={firstName}
                      options={firstNameOptions}
                      onChange={(e, option) => {
                        setFirstName(option);
                      }}
                      onInputChange={(event, newInputValue) => {
                        handleSearchFirstName(newInputValue);
                        field.onChange(newInputValue);
                      }}
                      ListboxComponent="div"
                      ListboxProps={{ sx: ListBoxCustom }}
                      // PopperComponent={isMobile && "bottom"}
                      noOptionsText={false}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          inputRef={ref}
                          label={firstName ? " " : "e.g. Jonh"}
                          size={isMobile ? "small" : "medium"}
                          sx={textFieldCustom}
                          className={
                            openFirstName && firstNameOptions.length > 0
                              ? "show"
                              : ""
                          }
                          InputLabelProps={{
                            shrink: false,
                            style: { ...textInputStyles, color: "#B7B7B7" }
                          }}
                          value={firstName}
                          error={!!errors?.firstName}
                          helperText={
                            errors?.firstName && errors?.firstName?.message
                          }
                          onChange={(e) => setFirstName(e.target.value)}
                        />
                      )}
                    />
                  </FormControl>
                )}
              />
              {/* firstName */}
              {/* lastName */}
              <Controller
                name="lastName"
                control={control}
                defaultValue={lastName}
                rules={{
                  required: "LastName is required!"
                }}
                render={({ field: { ref, ...field } }) => (
                  <FormControl
                    fullWidth
                    sx={{
                      marginBottom: isMobile ? "7px" : "15px"
                    }}
                  >
                    <FormHelperText sx={labelCustom}>Last name</FormHelperText>
                    <Autocomplete
                      {...field}
                      freeSolo
                      id="lastName"
                      name="lastName"
                      onOpen={() => setOpenLastName(true)}
                      onClose={() => setOpenLastName(false)}
                      disableClearable
                      value={lastName}
                      options={lastNameOptions}
                      onChange={(e, option) => {
                        setLastName(option);
                      }}
                      onInputChange={(event, newInputValue) => {
                        handleSearchLastName(newInputValue);
                        field.onChange(newInputValue);
                      }}
                      ListboxComponent="div"
                      ListboxProps={{ sx: ListBoxCustom }}
                      // PopperComponent={isMobile && "bottom"}
                      noOptionsText={false}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          inputRef={ref}
                          label={lastName ? " " : "e.g. Smith"}
                          sx={textFieldCustom}
                          className={
                            openLastName && lastNameOptions.length > 0
                              ? "show"
                              : ""
                          }
                          InputLabelProps={{
                            shrink: false,
                            style: { ...textInputStyles, color: "#B7B7B7" }
                          }}
                          size={isMobile ? "small" : "medium"}
                          value={lastName}
                          error={!!errors?.lastName}
                          helperText={
                            errors?.lastName && errors?.lastName?.message
                          }
                          onChange={(e) => setLastName(e.target.value)}
                        />
                      )}
                    />
                  </FormControl>
                )}
              />
              {/* lastName */}
            </Box>

            <Controller
              name="email"
              control={control}
              defaultValue={email}
              rules={{
                required: "Email is required!",
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: "Invalid email address"
                }
              }}
              render={({ field: { ref, ...field } }) => (
                <FormControl
                  fullWidth
                  sx={{
                    marginBottom: isMobile ? "7px" : "15px"
                  }}
                >
                  <FormHelperText sx={labelCustom}>
                    Your email address
                  </FormHelperText>
                  <Autocomplete
                    {...field}
                    freeSolo
                    id="email"
                    name="email"
                    onOpen={() => setOpenEmailOptions(true)}
                    onClose={() => setOpenEmailOptions(false)}
                    disableClearable
                    value={email}
                    options={emailOptions}
                    onChange={(e, option) => {
                      setEmail(option);
                    }}
                    onInputChange={(event, newInputValue) => {
                      handleInputChange(event, newInputValue);
                      field.onChange(newInputValue);
                    }}
                    ListboxComponent="div"
                    ListboxProps={{ sx: ListBoxCustom }}
                    // PopperComponent={isMobile && "bottom"}
                    noOptionsText={false}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={email ? "" : "e.g. john123@gmail.com"}
                        size={isMobile ? "small" : "medium"}
                        sx={{
                          ...textFieldCustom
                        }}
                        className={
                          openEmailOptions && emailOptions.length > 0
                            ? "show"
                            : ""
                        }
                        InputLabelProps={{
                          shrink: false,
                          style: {
                            ...textInputStyles,
                            color: "#B7B7B7"
                          }
                        }}
                        value={email}
                        error={!!errors?.email}
                        helperText={errors?.email && errors?.email?.message}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    )}
                  />
                </FormControl>
              )}
            />

            <Typography
              sx={{
                marginBottom: "6px",

                color: "#606060",
                textAlign: "justify",
                fontFamily: "Poppins",
                fontSize: "12px",
                fontStyle: "normal",
                fontWeight: 300,
                lineHeight: "140%"
              }}
            >
              By clicking <b>'Request Now'</b>, you agree to our{" "}
              <Link to="/privacy">Privacy Policy</Link>,{" "}
              <Link to="/terms-of-use">Terms</Link>
              {", "}
              <Link onClick={() => setOpenRateFee(!openRateFee)}>
                Rate & Fees
              </Link>{" "}
              and receive special offers from us and our marketing partners via
              email communication
            </Typography>
            <LoadingButton
              endIcon={<></>}
              // endIcon={<SendIcon />}
              loading={loadingButton}
              loadingPosition="end"
              variant="contained"
              size="large"
              style={btnSubmitCustom}
              onClick={() => {
                handleSubmit(onSubmit);
              }}
              type="submit"
            >
              <span>REQUEST NOW</span>
            </LoadingButton>
          </form>

          {/* FORM */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between"
            }}
          >
            <Box
              className="item"
              sx={{ display: "flex", gap: "5px", alignItems: "center" }}
            >
              <img
                src={DataProtected}
                alt="DataProtected"
                style={{
                  maxWidth: isMobile && "12px"
                }}
              />
              <Typography sx={securityText}>DATA PROTECTED</Typography>
            </Box>
            <Box
              className="item"
              sx={{ display: "flex", gap: "5px", alignItems: "center" }}
            >
              <img
                src={SSL}
                alt="DataProtected"
                style={{
                  maxWidth: isMobile && "12px"
                }}
              />
              <Typography sx={securityText}>ENCRYPTED DATA</Typography>
            </Box>
            <Box
              className="item"
              sx={{ display: "flex", gap: "5px", alignItems: "center" }}
            >
              <img
                src={Clear}
                alt="DataProtected"
                style={{
                  maxWidth: isMobile && "12px"
                }}
              />
              <Typography sx={securityText}>CLEAR-CUT FORM</Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Banner;
