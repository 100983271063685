import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

const emailDomains = ["gmail.com", "outlook.com", "yahoo.com", "example.com"];

export function EmailComboBox() {
  const [email, setEmail] = useState("");
  const [emailOptions, setEmailOptions] = useState([]);
  const [openEmailOptions, setOpenEmailOptions] = useState(false);

  const handleInputChange = (e, newInputValue) => {
    setEmail(newInputValue);

    if (newInputValue.includes("@")) {
      const [email, domain] = newInputValue.split("@");
      const filteredOptions = emailDomains
        .filter((option) => option.includes(domain))
        .map((option) => `${email}@${option}`);
      setEmailOptions(filteredOptions);
      setOpenEmailOptions(true);
    } else {
      setEmailOptions([]);
      setOpenEmailOptions(false);
    }
  };

  return (
    <Autocomplete
      id="email-combobox"
      options={emailOptions}
      value={email}
      open={openEmailOptions}
      onInputChange={handleInputChange}
      renderInput={(params) => (
        <TextField {...params} label="Email" variant="outlined" />
      )}
      noOptionsText={false}
    />
  );
}
