import React from "react";
import Navbar from "../../../components/Navbar/Navbar";
import Footer from "../../../components/Footer/Footer";

import "./Disclaimer.scss";
import { Link } from "react-router-dom";

const Disclaimer = () => {
  return (
    <div className="Disclaimer">
      <Navbar />

      <div className="container-privacy">
        <div className="box-privacy">
          <h2 className="title">Disclaimer</h2>

          <p>
            <b>
              <Link to="/">lowrateseeker.com</Link>
            </b>{" "}
            is not a lender, or a representative, broker, agent of any lenders
            and we do not make any loan or credit decisions. This Site does not
            constitute an offer or solicitation to lend. The role of this
            website is to submit the information you provide to a lender who has
            the whole rights of reviewing and approving a loan. By submitting
            your information, you agree to allow participating lenders to verify
            your information and check your credit. Not all lenders can provide
            the maximum amount advertised. The time of cash transfer may vary
            between lenders and may depend on your individual financial
            institution. In some circumstances faxing may be required. Not all
            lenders from our system operate in all US states, and the states
            serviced by this Web Site may change from time to time and without
            notice. If you have any questions about your loan, please contact
            your lеndеr directly. Short term cash loans are meant to address
            immediate cash needs and are not a long-term solution for financial
            problems. Residents of some states may not be eligible for a short
            term cash loan based upon lender requirements.
          </p>

          <p>
            <b>
              <Link to="/">lowrateseeker.com</Link>
            </b>{" "}
            is a free-of-charge platform which collects your personal details to
            submit for the lenders to review your loan requests. We do not
            relate to the reviewing process or address your loan-related
            questions, please contact your financial providers directly to
            clarify all the information. We try our best to connect you with the
            most suitable lender, however the approval decision will be fully
            determined by the lender.{" "}
          </p>
          <p>
            By reading Privacy Policy and Terms of Use, you understand and
            consent that this Site collects personal details and shares with its
            third-party partners. Also, the links of third-party websites are
            presented on this Site. For any loan reasons, you should connect
            with a consultation specialist before deciding your loan.
          </p>
        </div>
      </div>

      <Footer desc={false} />
    </div>
  );
};

export default Disclaimer;
