import React from "react";
import Navbar from "../../../components/Navbar/Navbar";
import Footer from "../../../components/Footer/Footer";

import "./ContactUs.scss";
import { Link } from "react-router-dom";

const ContactUs = () => {
  return (
    <div className="ContactUs">
      <Navbar />

      <div className="container-privacy">
        <div className="box-privacy">
          <h2 className="title">Contact Us</h2>

          <p>
            If there is something you would like to discuss with us related to
            the site, please contact us following the below contact details. If
            you have any questions regarding the loan request, please contact
            the lender directly.
          </p>

          <h4>Mailing Address: </h4>
          <p>Lowrateseeker.com </p>

          <p>2803 Philadelphia Pike</p>
          <p>Suite B #1020</p>
          <p>Claymont, DE 19703</p>
          <p>United States</p>
          <p>
            Email:{" "}
            <b><Link target="_blank" to="mailto:contact@Lowrateseeker.com">
              contact@Lowrateseeker.com
            </Link></b>
          </p>
        </div>
      </div>

      <Footer desc={false} />
    </div>
  );
};

export default ContactUs;
