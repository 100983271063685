import dayjs from "dayjs";
import { publicRequest } from "../requestMethods";

const convertDateFormat = (data) => {
  return data.replace(/-/g, "/");
};

export const sendDataPhonexa = async (contactRedux) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      const data = {
        apiId: "F4AB435C54964AC19678B52CD6010C00", // ✔
        apiPassword: "5ed01458", // ✔
        productId: "19", // ✔
        price: "0", // ✔
        loanAmount:
          contactRedux?.amount && contactRedux?.amount.split("$").join(""), // Home
        workCompanyName: contactRedux?.employerName, // step 11
        jobTitle: contactRedux?.jobTitle, // step 11
        activeMilitary:
          contactRedux?.incomeSource === "MILITARY" ? "YES" : "NO", // step 8: chọn option "Military" thì value "YES", ngược lại "NO"
        workTimeAtEmployer: contactRedux?.currentCompanyDuration, // step 10: Lấy số tháng giữa các khoảng?
        ssn:
          contactRedux?.socialSecurityNumber &&
          contactRedux?.socialSecurityNumber.split("-").join(""), // step 18
        driversLicenseNumber: contactRedux?.licenseID, // step 16
        driversLicenseState: contactRedux?.issuingState, // step 16
        incomeType:
          contactRedux?.incomeSource === "MILITARY"
            ? "SELF_EMPLOYMENT"
            : contactRedux?.incomeSource, // step 8: nếu chọn option "Military" thì value bằng?
        incomePaymentFrequency: contactRedux?.payFrequency, // step 9
        incomeNetMonthly: contactRedux?.monthlyIncome, // step 9: value là giá trị giữa các khoảng?
        incomeNextDate1:
          contactRedux?.nextPayDate &&
          dayjs(convertDateFormat(contactRedux?.nextPayDate)).format(
            "YYYY-MM-DD"
          ), // step 9
        incomeNextDate2:
          contactRedux?.nextPayDate2 &&
          dayjs(convertDateFormat(contactRedux?.nextPayDate2)).format(
            "YYYY-MM-DD"
          ), // step 9
        bankDirectDeposit: contactRedux?.getDirectDeposit, // step 14
        bankAba: contactRedux?.routingNumber, // step 13
        bankName: contactRedux?.bankName, // step 13
        bankAccountNumber: contactRedux?.bankAccount, // step 13
        bankAccountType: contactRedux?.bankAccountType, // step 13
        firstName: contactRedux?.firstName, // step 2
        lastName: contactRedux?.lastName, // step 2
        dob:
          contactRedux?.dob &&
          dayjs(convertDateFormat(contactRedux?.dob)).format("YYYY-MM-DD"), // step 2
        address: contactRedux?.streetAddress, // step 5
        city: contactRedux?.zipInfo?.default_city, // step 5 get api
        state: contactRedux?.zipInfo?.state_abbreviation, // step 5 get api
        zip: contactRedux?.zipCode, // step 5
        ownHome: contactRedux?.residenceOwnership, // step 7
        email: contactRedux?.email, // Home
        homePhone:
          contactRedux?.phone && contactRedux?.phone.replace(/[-()]/g, ""), // step 3
        workPhone:
          contactRedux?.employerPhone &&
          contactRedux?.employerPhone.replace(/[-()]/g, ""), // step 11
        cellPhone:
          contactRedux?.phone && contactRedux?.phone.replace(/[-()]/g, ""), // step 3
        consentEmailSms: "YES", // AUTO YES
        userIp: contactRedux?.ipAddress, // GET API
        contactTime: contactRedux?.bestTimeContact, // step 4
        creditScore: contactRedux?.creditScoreRating, // step 12
        loanReason: contactRedux?.loanReason, // step 1
        userAgent: contactRedux?.userAgent, // check browser
        loanreason_in: contactRedux?.loanReason, // step 1
        debt_amount: contactRedux?.newUnsecuredDebtAmount, // Step 19
        time_at_residence: contactRedux?.currentResidenceDuration, // step 6
        own_car: contactRedux?.ownCar, // step 17,
        source: window.location.hostname ?? ""
      };

      var testMode = localStorage.getItem("testMode") ?? "";
      var testSold = localStorage.getItem("testSold") ?? "";
      var clickid = localStorage.getItem("clickId") ?? "";

      if (testMode) data.testMode = testMode;
      if (testSold) data.testSold = testSold;
      if (clickid) data.clickid = clickid;

      return publicRequest
        .post("/phonexa", data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          throw error;
        });
    }, 0);
  });
};
// export const sendDataPhonexa = async (contactRedux) => {
//   return new Promise((resolve) => {
//     setTimeout(() => {
//       resolve({
//         data: {
//           // redirect_url: "https://www.facebook.com/"
//           rejectUrl: "https://www.youtube.com/"
//         }
//       });
//     }, 0);
//   });
// };

export const sendDataBrevo = async (email, firstName, lastName) => {
  return new Promise((resolve) => {
    const data = {
      email: email,
      attributes: {
        FIRSTNAME: firstName,
        LASTNAME: lastName
      },
      emailBlacklisted: false,
      smsBlacklisted: false,
      updateEnabled: false,
      smtpBlacklistSender: ["info@sendinblue.com"]
    };

    return publicRequest
      .post("/brevo", data)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        throw error;
      });
  });
};
export const sendDataEdelivery = async (email, firstName, lastName) => {
  return new Promise((resolve) => {
    const data = {
      list_id: 32,
      email: email,
      fname: firstName,
      lname: lastName
    };

    return publicRequest
      .post("/phonexa/edelivery", data)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        throw error;
      });
  });
};

export const sendDataMailjet = async (email, firstName, lastName) => {
  return new Promise((resolve) => {
    const data = {
      Email: email,
      Properties: {
        firstname: firstName,
        name: lastName
      }
    };

    return publicRequest
      .post("/send-mail/mailjet", data)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        throw error;
      });
  });
};

export const sendDataMyMarketing = async (email, firstName, lastName) => {
  return new Promise((resolve) => {
    const data = {
      first_name: firstName,
      last_name: lastName,
      email: email
    };

    return publicRequest
      .post("/send-mail/mymarketing", data)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        throw error;
      });
  });
};

export const sendDataMailchimp = async (email, firstName, lastName) => {
  return new Promise((resolve) => {
    const data = {
      first_name: firstName,
      last_name: lastName,
      email: email
    };

    return publicRequest
      .post("/send-mail/mailchimp", data)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        throw error;
      });
  });
};
export const searchFirstNameApi = async (firstName) => {
  try {
    const res = await publicRequest.get(
      `/contact/search?firstName=${firstName}`
    );
    if (res?.data?.firstName?.length > 0) {
      return res.data.firstName;
    } else {
      return [];
    }
  } catch (error) {}
};
export const searchLastNameApi = async (lastName) => {
  try {
    const res = await publicRequest.get(`/contact/search?lastName=${lastName}`);
    if (res?.data?.lastName?.length > 0) {
      return res.data.lastName;
    } else {
      return [];
    }
  } catch (error) {}
};
export const searchJobTitleApi = async (jobTitle) => {
  try {
    const res = await publicRequest.get(`/contact/search?jobTitle=${jobTitle}`);
    if (res?.data?.jobTitle?.length > 0) {
      return res.data.jobTitle;
    } else {
      return [];
    }
  } catch (error) {}
};
export const searchEmployerNameApi = async (employerName) => {
  try {
    const res = await publicRequest.get(
      `/contact/search?employerName=${employerName}`
    );
    if (res?.data?.employerName?.length > 0) {
      return res.data.employerName;
    } else {
      return [];
    }
  } catch (error) {}
};
