import { createSlice } from "@reduxjs/toolkit";

const contactSlice = createSlice({
  name: "contact",
  initialState: {
    contact: null,
    isFetching: false,
    error: false
  },
  reducers: {
    addContactStart: (state) => {
      state.isFetching = true;
    },
    addContactSuccess: (state, actions) => {
      state.isFetching = false;
      state.contact = {
        ...state.contact, // Sao chép dữ liệu liên hệ hiện tại
        ...actions.payload // Cập nhật thông tin mới từ actions.payload
      };
    },
    addContactFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    },
    // UPDATE
    updateContactStart: (state) => {
      state.isFetching = true;
    },
    // updateContactSuccess: (state, actions) => {
    //   state.isFetching = false;
    //   state.contact = actions.payload;
    // },
    updateContactSuccess: (state, actions) => {
      state.isFetching = false;
      state.contact = {
        ...state.contact, // Sao chép dữ liệu liên hệ hiện tại
        ...actions.payload // Cập nhật thông tin mới từ actions.payload
      };
    },
    updateContactFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    }
  }
});

export const {
  addContactStart,
  addContactSuccess,
  addContactFailure,
  updateContactStart,
  updateContactSuccess,
  updateContactFailure
} = contactSlice.actions;
export default contactSlice.reducer;
