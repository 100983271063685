import Home from "./pages/Home/Home";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate
} from "react-router-dom";
import { useEffect } from "react";
import "./theme.scss";
import AOS from "aos";
import "aos/dist/aos.css";

import Unsubscribe from "./pages/AnotherPages/Unsubscribe/Unsubscribe";
import DoNotSellInformation from "./pages/AnotherPages/DoNotSellInformation/DoNotSellInformation";
import TermsOfUse from "./pages/AnotherPages/TermsOfUse/TermsOfUse";
import Privacy from "./pages/AnotherPages/Privacy/Privacy";
import ContactUs from "./pages/AnotherPages/ContactUs/ContactUs";
import Disclaimer from "./pages/AnotherPages/Disclaimer/Disclaimer";
import FrequentlyAskedQuestions from "./pages/AnotherPages/FrequentlyAskedQuestions/FrequentlyAskedQuestions";
import { EmailComboBox } from "./pages/test";
import Configuration from "./pages/Steps/Configuration/Configuration";
import RedirectPageUpstart from "./pages/Steps/RedirectPageUpstart/RedirectPageUpstart";
import RedirectPageUpgrade from "./pages/Steps/RedirectPageUpgrade/RedirectPageUpgrade";
import RedirectPage from "./pages/Steps/RedirectPage/RedirectPage";
import NewForm from "./pages/NewForm/NewForm";

const App = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000
    });
    AOS.refresh();
  }, []);

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Navigate to="/apply" />} />
        <Route exact path="/apply" element={<Home />}></Route>

        <Route path="/congratulations" element={<Configuration />}></Route>
        <Route path="/unsubscribe" element={<Unsubscribe />}></Route>
        <Route
          path="/do-not-sell-information"
          element={<DoNotSellInformation />}
        ></Route>
        <Route path="/terms-of-use" element={<TermsOfUse />}></Route>
        <Route path="/privacy" element={<Privacy />}></Route>
        <Route path="/contact-us" element={<ContactUs />}></Route>
        <Route path="/disclaimer" element={<Disclaimer />}></Route>
        <Route
          path="/frequently-asked-questions"
          element={<FrequentlyAskedQuestions />}
        ></Route>
        <Route path="/test" element={<EmailComboBox />}></Route>
        <Route path="/configuration" element={<Configuration />}></Route>
        <Route
          path="/redirect-upstart"
          element={<RedirectPageUpstart />}
        ></Route>
        <Route
          path="/redirect-upgrade"
          element={<RedirectPageUpgrade />}
        ></Route>
        <Route path="/redirect" element={<RedirectPage />}></Route>
        <Route path="/form" element={<NewForm />}></Route>
      </Routes>
    </Router>
  );
};
export default App;
