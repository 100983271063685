import React from "react";
import "./ReadyToApply.scss";
import { NextBtn } from "../Button/NextBtn/NextBtn";
import { Link as Scroll } from "react-scroll";
import { btnSubmitCustom } from "./style";
import { Box, Typography } from "@mui/material";

const ReadyToApply = () => {
  const windowWidth = window.innerWidth;
  const isMobile = windowWidth < 768;

  return (
    <Box
      className="ready-to-apply"
      data-aos="fade-down"
      data-aos-offset="-170"
      sx={{
        maxWidth: "1041px",
        margin: isMobile ? "0 auto" : "0 auto 47px",
        padding: isMobile ? "0 20px 35px" : 0
      }}
    >
      <Box
        sx={{
          backgroundColor: "#fff",
          padding: isMobile ? "14px 14px 20px" : "37px 15px",
          boxShadow: "0px 1px 20px rgba(0, 0, 0, 0.10)",
          borderRadius: "8px"
        }}
      >
        <Typography
          component="h3"
          sx={{
            marginBottom: "5px",

            color: "#00A872",
            textAlign: "center",
            fontFamily: "Poppins",
            fontSize: isMobile ? "20px" : "25px",
            fontStyle: "normal",
            fontWeight: 600,
            lineHeight: "normal"
          }}
        >
          READY TO REQUEST THE FUNDS YOU NEED?
        </Typography>
        <Box
          sx={{
            textAlign: "center"
          }}
        >
          <Typography
            component="p"
            sx={{
              display: "inline-block",
              marginBottom: isMobile ? "12px" : "21px",

              color: "#606060",
              textAlign: "center",
              fontFamily: "Poppins",
              fontSize: "16px",
              fontStyle: "normal",
              fontWeight: 400,
              lineHeight: "normal"
            }}
          >
            No matter what you need the loan for, money will be in your account
            if the request is approved.
          </Typography>
        </Box>
        <Box>
          <Scroll
            className="menu-item"
            to="banner"
            spy={true}
            smooth={true}
            duration={800}
            offset={-200}
          >
            <NextBtn
              element="button"
              style={{
                ...btnSubmitCustom,
                maxWidth: isMobile ? "100%" : "390px",
                margin: "0 auto"
              }}
              label="Request Now"
            />
          </Scroll>
        </Box>
      </Box>
    </Box>
  );
};

export default ReadyToApply;
