export const amountOptions = [
  {
    value: "100",
    label: "$100"
  },
  {
    value: "200",
    label: "$200"
  },
  {
    value: "300",
    label: "$300"
  },
  {
    value: "400",
    label: "$400"
  },
  {
    value: "500",
    label: "$500"
  },
  {
    value: "600",
    label: "$600"
  },
  {
    value: "700",
    label: "$700"
  },
  {
    value: "800",
    label: "$800"
  },
  {
    value: "900",
    label: "$900"
  },
  {
    value: "1000",
    label: "$1000"
  },
  {
    value: "1500",
    label: "$1,500"
  },
  {
    value: "2000",
    label: "$2,000"
  },
  {
    value: "2500",
    label: "$2,500"
  },
  {
    value: "3000",
    label: "$3,000"
  },
  {
    value: "3500",
    label: "$3,500"
  },
  {
    value: "4000",
    label: "$4,000"
  },
  {
    value: "4500",
    label: "$4,500"
  },
  {
    value: "5000",
    label: "$5,000"
  }
];
